import React from 'react';
import { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { WidgetContext } from './WidgetContext';

type PackagePickerWidgetContextProps = {
  locale?: string;
};
export const PackagePickerWidgetContext: React.FC<PackagePickerWidgetContextProps> = ({ children, locale }) => {
  return (
    <WidgetContext
      settingsAdapterClass={DefaultSettingsAdapter}
      settingsParams={settingsParams}
      stylesParams={stylesParams}
      locale={locale}
    >
      {children}
    </WidgetContext>
  );
};
